import React, { useState, useEffect } from "react"
import { Col, Row, Form, Card } from "reactstrap"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { useHistory } from "react-router-dom"

import { TextInput } from "../../components/TextInput"
import { Button } from "../../components/Button"
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText"

import {
   DepartmentListing,
   GetTrailerTypeDetails,
   AddTrailerType,
   UpdateTrailerType,
} from "../../api/api.service"

import { FIELDS_NAME, initialValues, validationSchema } from "./FormConfig"
import { TrailerTypeColors } from "../../constants"
import FormSkeleton from "components/FormSkeleton/FormSkeleton"
import "react-datepicker/dist/react-datepicker.css"
import { AutoComplete } from "components/AutoComplete"
import useAsyncSelect from "hooks/useAsyncSelect"
import { AutoCompleteStyling } from "../Common/AutoCompleteStyling"
import { getSelectBoxOptionsFromConstants } from "utils/commonUtils"
import BuildDepartments from "./BuildDepartments"
import { APP_ROUTES } from "helpers/routeHelpers"

const TITLES = {
   ADD_BTN_TEXT: "Save",
   UPDATE_BTN_TEXT: "Update",
   DETAILS_BTN_TEXT: "Done",
}

const makeOptionsDisabled = (checkingList = [], options = []) => {
   const tempData = [...options]

   let ids = checkingList.map(item => item.id)

   let newArr = tempData.filter(item => !ids.includes(item.id))

   return newArr
}

const ManageTrailerManagement = ({ id = null, isDetailView = false }) => {
   const history = useHistory()
   const [isLoading, setIsLoading] = useState(false)
   const [isFetching, setIsFetching] = useState(false)
   const [buildDepartments, setBuildDepartments] = useState({})

   const formik = useFormik({
      enableReinitialze: true,
      initialValues: {
         ...initialValues,
      },
      validationSchema: validationSchema,
      onSubmit: values => {
         handleSubmit(values)
      },
   })

   const {
      setSelectedOption: setSelectedDepartment,
      selectEntity: departmentOptions,
      setSelectEntity: setDepartmentOption,
      handleSearchOnOptions: handleSearchDepartment,
      selectedOption: selectedDepartments,
      handleSelectAOption: handleSelectADepartment,
   } = useAsyncSelect({
      apiFunc: DepartmentListing,
      labelKey: "departmentName",
      valueKey: "id",
      params: {},
      restrictExecutionOnRender: isDetailView,
      onOptionSelect: option => {
         setBuildDepartments(prev => ({
            ...prev,
            [option.id]: {
               ...option,
            },
         }))
      },
   })

   useEffect(() => {
      handleFetchDetails()
      return () => {
         setIsLoading(false)
      }
   }, [id])

   const handleSubmit = values => {
      const bodyData = {
         trailerNo: values[FIELDS_NAME.TRAILER_NO],
         trailerType: values[FIELDS_NAME.TRAILER_TYPE],
         colors: id
            ? values[FIELDS_NAME.COLORS].map(item => item.value)
            : values[FIELDS_NAME.COLORS].map(item => ({
                 trailerColor: item.value,
              })),

         trailerDepartments: Object.values(buildDepartments)?.map(item => ({
            departmentId: item.id,
            trailerDepartmentsItems: item?.items?.map(obj => ({
               itemsId: obj.itemsId,
               quantity: obj.quantity,
            })),
         })),
      }

      if (id) {
         handleUpdate(bodyData)
      } else {
         handleAdd(bodyData)
      }
   }

   const handleUpdate = async data => {
      try {
         setIsLoading(true)

         let res = await UpdateTrailerType(id, data)
         history.push(APP_ROUTES.TRAILERS_MANAGEMENT)
         toast.success("Trailer type has been updated successfully !")
      } finally {
         setIsLoading(true)
      }
   }
   const handleAdd = async data => {
      try {
         setIsLoading(true)
         let res = await AddTrailerType(data)
         history.push(APP_ROUTES.TRAILERS_MANAGEMENT)
         toast.success("Trailer type has been added successfully !")
      } catch (error) {
      } finally {
         setIsLoading(false)
      }
   }

   const handleFetchDetails = async () => {
      if (!id) return

      try {
         setIsFetching(true)
         let res = await GetTrailerTypeDetails(id)

         setSelectedDepartment({
            label: res?.trailerType?.trailerType,
            value: res?.trailerType?.id,
         })

         formik.setValues({
            [FIELDS_NAME.TRAILER_NO]: res?.trailerNo,
            [FIELDS_NAME.TRAILER_TYPE]: res?.trailerType,
            [FIELDS_NAME.COLORS]: res?.colors?.map(item => ({
               label: TrailerTypeColors.getDisplayTextKey(item),
               value: item,
            })),
         })

         var newDepartments = {}

         res.departments.forEach(obj => {
            newDepartments[obj.department.id] = {
               ...obj.department,
               items: [...obj.items].map(itemData => ({
                  requiredQuantity: itemData.quantity,
                  quantity: Number(itemData.quantity),
                  itemsId: itemData.item.id,
                  itemName: itemData.item.itemName,
                  skuNo: itemData.item.skuNo,
               })),
            }
         })

         setBuildDepartments(prev => ({
            ...prev,
            ...newDepartments,
         }))
      } finally {
         setIsFetching(false)
      }
   }

   if (isFetching) {
      return <FormSkeleton />
   }

   return (
      <div style={{ pointerEvents: isLoading ? "none" : "auto" }}>
         <Form>
            <div className="mb-3 trailer-container">
               <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness trailer-btn-container">
                  <h1 className="header-title mb-0">Add Trailer Type</h1>

                  <Button
                     className={`header-button headerButtonResponsive`}
                     isLoading={isLoading}
                     type={isDetailView ? "button" : "submit"}
                     title={
                        isDetailView
                           ? TITLES.DETAILS_BTN_TEXT
                           : id
                           ? TITLES.UPDATE_BTN_TEXT
                           : TITLES.ADD_BTN_TEXT
                     }
                     onClick={
                        isDetailView
                           ? () => history.push(APP_ROUTES.TRAILERS_MANAGEMENT)
                           : formik.handleSubmit
                     }
                  />
               </div>
            </div>
            <Card className="p-2">
               <Row>
                  <Col sm={12} md={6} lg={3}>
                     <small className=" col-md-3 col-form-label clr-theme-primary">
                        Trailer Type No.
                     </small>
                     <div className="col-md-9">
                        <div className="my-2">
                           <TextInput
                              disabled={isDetailView}
                              type="text"
                              inputClass={` add-trailer-input`}
                              placeholder="Enter trailer No."
                              name={FIELDS_NAME.TRAILER_NO}
                              {...formik.getFieldProps(FIELDS_NAME.TRAILER_NO)}
                           />

                           <FormikErrorText
                              formikInstance={formik}
                              fieldName={FIELDS_NAME.TRAILER_NO}
                           />
                        </div>
                     </div>
                  </Col>

                  <Col sm={12} md={6} lg={3}>
                     <small className=" col-md-3 col-form-label clr-theme-primary">
                        Trailer Type
                     </small>
                     <div className="col-md-9">
                        <div className="my-2">
                           <TextInput
                              type="text"
                              disabled={isDetailView}
                              inputClass={`add-trailer-input `}
                              placeholder="Enter trailer type"
                              name={FIELDS_NAME.TRAILER_TYPE}
                              {...formik.getFieldProps(
                                 FIELDS_NAME.TRAILER_TYPE
                              )}
                           />

                           <FormikErrorText
                              formikInstance={formik}
                              fieldName={FIELDS_NAME.TRAILER_TYPE}
                           />
                        </div>
                     </div>
                  </Col>

                  {!isDetailView && (
                     <Col sm={12} md={6} lg={3}>
                        <small className=" col-md-3 col-form-label clr-theme-primary">
                           Stations
                        </small>

                        <div className="col-md-9">
                           {isDetailView ? (
                              <small>{selectedDepartments?.label}</small>
                           ) : (
                              <div className="my-2">
                                 <AutoComplete
                                    handleBlur={() =>
                                       setDepartmentOption(prev => {
                                          return {
                                             ...prev,
                                             page: 1,
                                             Q: "",
                                          }
                                       })
                                    }
                                    onChange={handleSelectADepartment}
                                    onInputChange={handleSearchDepartment}
                                    placeholder="Select stations"
                                    classNamePrefix="add-new-req"
                                    customStyles={AutoCompleteStyling}
                                    options={makeOptionsDisabled(
                                       Object.values(buildDepartments),
                                       departmentOptions?.data
                                    )}
                                    value={null}
                                    isLoading={departmentOptions?.fetching}
                                    name={FIELDS_NAME.STATIONS}
                                 />
                                 <FormikErrorText
                                    formikInstance={formik}
                                    fieldName={FIELDS_NAME.STATIONS}
                                 />
                              </div>
                           )}
                        </div>
                     </Col>
                  )}

                  <Col sm={12} md={6} lg={3}>
                     <small className=" col-md-3 col-form-label clr-theme-primary">
                        Colors
                     </small>

                     <div className="col-md-9">
                        <div className="my-2">
                           <AutoComplete
                              isDisabled={isDetailView}
                              isMulti
                              isClearable
                              onChange={val => {
                                 return formik.setFieldValue(
                                    FIELDS_NAME.COLORS,
                                    val
                                 )
                              }}
                              isSearchable={false}
                              placeholder="Select colors"
                              customStyles={AutoCompleteStyling}
                              classNamePrefix="add-new-req"
                              options={getSelectBoxOptionsFromConstants(
                                 TrailerTypeColors
                              )}
                              name={FIELDS_NAME.COLORS}
                              value={formik.values[FIELDS_NAME.COLORS]}
                           />

                           <FormikErrorText
                              formikInstance={formik}
                              fieldName={FIELDS_NAME.COLORS}
                           />
                        </div>
                     </div>
                  </Col>
               </Row>

               <BuildDepartments
                  isDetailView={isDetailView}
                  data={Object.values(buildDepartments)}
                  mutateDepartment={setBuildDepartments}
                  isFetching={isFetching}
               />
            </Card>
         </Form>
      </div>
   )
}

export default React.memo(ManageTrailerManagement)
